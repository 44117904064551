<template>
  <div v-if="content" class="container">
    <div class="row">
      <div class="col-7 logo">
        <img
          src="/images/logo.jpg"
          alt="Ifero 2.0"
          class="d-inline-block align-text-top"
        />
      </div>
      <div class="col-5 small">
        <div class="small">
          <b>PONUDA BR:</b> {{ content.doc_nr }}<br />
          Datum ponude:: {{ dateFormat(content.createdAt) }}<br />
          Mesto izdavanja ponude:: Beograd<br />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-7 small">
        Prodavac:<br />
        <div class="border-top border-bottom small">
          {{ seller.name }}<br />
          {{ seller.addr }}<br />
          PIB: {{ seller.pib }}<br />
          Matični broj: {{ seller.mb }}<br />
          Tekući račun: {{ seller.tr2 }}<br />
          Tel: {{ seller.tel }}
        </div>
      </div>
      <div class="col-5 small">
        Kupac:
        <div class="border-top border-bottom small">
          {{ content.Buyer ? content.Buyer.name : "" }}<br />
          {{ content.Buyer ? content.Buyer.address : "" }}<br />
          {{ content.Buyer ? content.Buyer.zip : "" }}
          {{ content.Buyer ? content.Buyer.city : "" }},
          {{ content.Buyer ? content.Buyer.country : "" }}<br />
          PIB: {{ content.Buyer ? content.Buyer.pib : "" }}<br />
          Matični broj: {{ content.Buyer ? content.Buyer.mb : "" }} <br />
          Tekući račun: {{ content.Buyer ? content.Buyer.bank_account : "" }}<br />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        Poštovani,<br />
        Na Vaš zahtev nudimo Vam
        {{ content.article ? content.article.name : "" }}
        {{ content.damages ? ". Vozilo je pretrpelo totalnu štetu.": "" }}
      </div>
    </div>
    <div class="row border-top border-bottom">
      <div class="col-3 small">
        Marka vozila: <br />
        Tip vozila: <br />
        Broj registracije: <br />
        Broj šasije vozila: <br />
        Broj motora vozila: <br />
        Godina proizvodnje: <br />
      </div>
      <div class="col-3 small fw-bold">
        {{ content.brand }}<br />
        {{ content.model }}<br />
        {{ content.registration_nr }}<br />
        {{ content.chassis_nr }}<br />
        {{ content.engine_nr }}<br />
        {{ content.year }}<br />
      </div>
      <div class="col-3 small">
        Prva registracija:<br />
        Snaga motora (KW):<br />
        Radna zapremina (ccm):<br />
        Broj sedišta: <br />
        Boja karoserije:<br />
        Pređena kilometraža (KM):<br />
      </div>
      <div class="col-3 small fw-bold">
        {{ dateFormat(content.first_registration) }}<br />
        {{ content.engine_power }}<br />
        {{ content.engine_volume }}<br />
        {{ content.seats }}<br />
        {{ content.color }}<br />
        {{ content.km }}<br />
      </div>
    </div>
    <div class="row my-3">
      <div class="col-7 small"></div>
      <div class="col-3 small text-end gray">
        PORESKA OSNOVICA: <br />
        PDV: <br />
        UKUPNO ZA PLAĆANE: <br />
      </div>
      <div class="col-2 small fw-bold text-end border-top border-bottom">
        {{ prices ? numFormat(prices.vat_base) : "" }} {{content.exchange}}<br />
        {{ prices ? numFormat(prices.vat) : "" }} {{content.exchange}}<br />
        {{ prices ? numFormat(prices.amount) : "" }} {{content.exchange}}<br />
      </div>
    </div>
    <div class="row my-2">
      <div class="col-12 small">
        NAPOMENA:<br />
        Prodavac napominje da je vozilo bilo korišćeno u svrhu -
        {{ content.purpose == 1 ? "dugoročni poslovni najam" : "rent a car"
        }}<br />
        Rok isporuke vozila je {{ content.delivery_time }} dana od datuma
        uplate. Ponuda važi {{ content.offer_validity }} dana.<br />
        Način plaćanja: uplatom na žiro račun, gotovinski i kreditnom karticom
        (Visa, Master Card, Amex).<br />
        Plaćanje se vrši u dinarima, po srednjem kursu NBS na dan uplate.<br />
        Ukoliko se u ovom periodu dogode promene koje su uzrokovala zakonodavna
        tela: promena u stopi PDV-a, promene u cenama, itd,
        {{ seller.name }} zadržava pravo da prema tim promenama usaglasi
        ponudu.<br />
      </div>
    </div>
    <Foother :type="$route.params.type" :date="''" />
  </div>
</template>

<script>
import dateUtils from "../../utils/date-common";
import utils from "../../utils/common";
import Foother from "./foother.vue";
export default {
  name: "offer",
  components: {
    Foother,
  },
  props: ["content"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
    numFormat(num) {
      return utils.toNumberFormat(num);
    },
  },
  data() {
    // return {
    //   content: {},
    // };
  },
  computed: {
    taxRates() {
      return this.$store.state.app.taxRates;
    },
    getTax() {
      const tax = this.content.article ? this.taxRates.find((t) => t.id == this.content.article.tax) : {rate: 20};
      return tax.rate;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    seller() {
      return this.$store.state.app.seller;
    },
    prices() {
      const vatRate = this.getTax;
      const base = this.content["bb_neto_eur"];
      const vats = utils.vatCalculate(base, vatRate);
      return {
        vat_base: Number(base),
        vat: Number(vats.vat),
        amount: Number(vats.amount),
        rate: Number(vatRate),
      };
    },
  },
};
</script>

<style scoped>
.container {
  /* border: 1px solid gray; */
  width: 800px;
  height: 900px;
}
.container .row > .col,
.container .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  /* background-color: rgba(39, 41, 43, 0.03); */
  /* border: 1px solid rgba(39, 41, 43, 0.1); */
}

.logo {
  padding: 0 !important;
}

img {
  width: 240px;
}

.sig {
  float: right;
}
tr {
  vertical-align: middle;
}
.gray {
  background: lightgray;
}
</style>